.wrapper {
    height: 70vh;
    width: 100%;
    /* background: linear-gradient(309deg, rgba(131, 58, 180, 1) 0%, rgba(200, 42, 18, 0.87718837535014) 79%) */
} 
.wrapper h3 {
    position: relative;
    top: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    opacity: 0.8
}

.boxes div {
    position: absolute;
    width: 50px;
    height: 50px;
    background-color: transparent;
    border: 2px solid rgb(76, 9, 231)
}

.boxes div:nth-child(1) {
    top: 70%;
    left: 10%;
    animation: box-animate 10s infinite
}

.boxes div:nth-child(2) {
    top: 20%;
    left: 80%;
    animation: box-animate 9s infinite
}

.boxes div:nth-child(3) {
    top: 50%;
    left: 50%;
    animation: box-animate 6s infinite
}

.boxes div:nth-child(4) {
    top: 80%;
    left: 60%;
    animation: box-animate 15s infinite
}

.boxes div:nth-child(5) {
    top: 30%;
    left: 30%;
    animation: box-animate 9s infinite
}

.boxes div:nth-child(6) {
    top: 90%;
    left: 90%;
    animation: box-animate 12s infinite
}

.boxes div:nth-child(7) {
    top: 80%;
    left: 30%;
    animation: box-animate 2s infinite
}

.boxes div:nth-child(8) {
    top: 40%;
    left: 20%;
    animation: box-animate 2s infinite
}

.boxes div:nth-child(9) {
    top: 50%;
    left: 80%;
    animation: box-animate 2s infinite
}

@keyframes box-animate {
    0% {
        transform: scale(0) translateY(0) rotate(0);
        opacity: 1
    }

    100% {
        transform: scale(1.3) translateY(-90px) rotate(360deg);
        opacity: 0
    }
}
